// import FeatureVideoWidget from "./FeatureVideoWidget";
// import DiscoverBikesWidget from "./DiscoverBikesWidget";
// import BikeFinderWidget from "./BikeFinderWidget";
// import WoomHeader from "./WoomHeader";
// import FormWidgetContainer from "./FormWidgetContainer";
// import GalleryWidget from "./GalleryWidget";
// import HighlightsWidgetContainer from "./HighlightsWidgetContainer";
// import HighlightWidget from "./HighlightWidget";
// import TeaserWidgetContainer from "./TeaserWidgetContainer";
// import LinkWidget from "./LinkWidget";
// import UspWidgetContainer from "./UspWidgetContainer";
// import ProductsWidgetContainer from "./ProductsWidgetContainer";
// import LookbookWidgetContainer from "./LookbookWidgetContainer";
// import LookbookHighlightWidget from "./LookbookHighlightWidget";
// import LookbookCollageWidget from "./LookbookCollageWidget";
// import LookbookSlideInWidget from "./LookbookSlideInWidget";
// import LookbookFooterWidget from "./LookbookFooterWidget";
// import IntroWidget from "./IntroWidget";
// import InlineNavWidgetContainer from "./InlineNavWidgetContainer";
// import EntriesWidgetContainer from "./EntriesWidgetContainer";
// import CampaignAssetWidget from "./CampaignAssetWidget";
// import ImageQuoteWidget from "./ImageQuoteWidget";
// import QuoteWidget from "./QuoteWidget";
// import VideoWidgetContainer from "./VideoWidgetContainer";
// import PodcastWidget from "./PodcastWidget";
// import InfoBlockWidget from "./InfoBlockWidget";
// import WidgetContainer from "./WidgetContainer";
// import AccordionWidgetContainer from "./AccordionWidgetContainer";
// import TextSliderWidgetContainer from "./TextSliderWidgetContainer";
// import TextWidget from "./TextWidget";
// import ProductAsset from "./ProductAsset";
// import Hero from "./Hero";
// import KlaviyoWidget from "./KlaviyoWidget";
// import GleamWidget from "./GleamWidget";
// import TypeFormWidget from "./TypeFormWidget";
// import CookieWidget from "./CookieWidget";
// import ProductVariantContainer from "./ProductVariantContainer";
// import BundleWidgetContainer from "./BundleWidgetContainer";
// import FriendbuyWidget from './FriendbuyWidget.vue';
// import GoogleFormWidgetContainer from './GoogleFormWidget.vue';

export const Widgets = {
    //   FeatureVideoWidget,
    //   DiscoverBikesWidget,
    //   BikeFinderWidget,
    //   WoomHeader,
    //   FormWidgetContainer,
    //   GalleryWidget,
    //   HighlightsWidgetContainer,
    //   HighlightWidget,
    //   TeaserWidgetContainer,
    //   LinkWidget,
    //   UspWidgetContainer,
    //   ProductAsset,
    //   ProductsWidgetContainer,
    //   IntroWidget,
    //   InlineNavWidgetContainer,
    //   EntriesWidgetContainer,
    //   CampaignAssetWidget,
    //   ImageQuoteWidget,
    //   QuoteWidget,
    //   VideoWidgetContainer,
    //   PodcastWidget,
    //   InfoBlockWidget,
    //   WidgetContainer,
    //   AccordionWidgetContainer,
    //   TextSliderWidgetContainer,
    //   TextWidget,
    //   Hero,
    //   LookbookWidgetContainer,
    //   LookbookHighlightWidget,
    //   LookbookCollageWidget,
    //   LookbookSlideInWidget,
    //   LookbookFooterWidget,
    //   KlaviyoWidget,
    //   GleamWidget,
    //   TypeFormWidget,
    //   CookieWidget,
    //   ProductVariantContainer,
    //   BundleWidgetContainer,
    //   FriendbuyWidget,
    //   GoogleFormWidgetContainer
};
