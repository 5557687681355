import { RefLookupFunction } from '~~/common/types';

/**
 *
 * @param {*} value the text value which contains links
 * @param {*} refLookup the reference lookup for url replacements
 */
export const textLinks = (value: string, refLookup: RefLookupFunction) => {
    // find all links
    const r = new RegExp(/<a data-item-id="([^"]+)" data-link-id="([^"]+)" href="">/, 'g');
    // console.log('check for text links in ', value)
    const textSplit = value.match(r);
    // console.log(textSplit)
    if (textSplit) {
        for (const a of textSplit) {
            // console.log('text split', a)
            const m = a.match(/<a data-item-id="([^"]+)" data-link-id="([^"]+)" href="">/);
            if (m) {
                const ret = refLookup(m[2], 'links');
                // console.log('a refLookup', m[2], ret)
                if (ret) {
                    // console.log('reference found for value', ret, value)
                    const urlPath = ret.url.url === '/' ? '' : ret.url.url;
                    const reg = new RegExp(`<a data-item-id="${m[1]}" data-link-id="${m[2]}" href="">`, 'ig');
                    value = value.replace(reg, `<a href="/${ret.url.locale}${urlPath}" class="text-link woom-link">`);
                    // console.log('new value', value)
                } else {
                    // console.log('no reference found for id', m[2])
                }
            }
        }
    }
    return value;
};
