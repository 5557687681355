<script setup lang="ts">
    import { PropsType, generatePageMixin, pageProps } from './PageMixin';

    const props: PropsType = defineProps({
        ...pageProps,
    });

    const { page, pageId, refs, getReference } = generatePageMixin(props);

    console.log('ContentPageRenderer.vue', {
        page: page.value,
        pageId: pageId.value,
        refs: refs.value,
    });
</script>

<template>
    <div class="page content-grid">
        <keep-alive>
            <cms-content-controller
                :data="page?.content"
                :ref-lookup="getReference"
                :page-id="pageId"
                class="page-controller content-grid" />
        </keep-alive>
    </div>
</template>

<style>
    .rich-text.text-padding a:hover {
        color: var(--color-red);
    }
    .page-controller.content-grid {
        grid-row-gap: 0px !important;
    }
</style>
