import { ExtractPropTypes } from '@vue/composition-api';
import { Prettify } from '@vue/shared';
import {
    PageByUrlQuery_PageByUrl,
    PageByUrlQuery_PageByUrl_Cannonical,
    PageByUrlQuery_PageByUrl_Page,
    PageByUrlQuery_PageByUrl_Refs,
    PageByUrlQuery_PageByUrl_SEO,
} from '~~/common/types/nestedGraphqlTypes';

type PropsTypeInner = {
    pageData: PageByUrlQuery_PageByUrl;
    seoFunc: Function;
};

// this is how defineProps wraps types
export type PropsType = Prettify<Readonly<ExtractPropTypes<PropsTypeInner>>>;

export const pageProps = {
    pageData: {
        type: Object as PropType<PageByUrlQuery_PageByUrl>,
        // type: Object as () => PageQuery_PageByUrl,
        required: true,
    },
    seoFunc: {
        type: Function,
        required: true,
    },
};

export const generatePageMixin = (props: PropsType) => {
    const computedProps = {
        page: computed<PageByUrlQuery_PageByUrl_Page | null>(() => {
            return (props.pageData as PageByUrlQuery_PageByUrl)?.page || null;
        }),
        refs: computed<PageByUrlQuery_PageByUrl_Refs | null>(() => {
            return (props.pageData as PageByUrlQuery_PageByUrl)?.refs || null;
        }),
        pageId: computed<string | null>(() => {
            return (props.pageData as PageByUrlQuery_PageByUrl)?.id || null;
        }),
        seo: computed<PageByUrlQuery_PageByUrl_SEO | null>(() => {
            return (props.pageData as PageByUrlQuery_PageByUrl)?.seo || null;
        }),
        cannonical: computed<PageByUrlQuery_PageByUrl_Cannonical | null>(() => {
            return (props.pageData as PageByUrlQuery_PageByUrl)?.cannonical || null;
        }),
    };

    const methods = {
        getReference: (id: string, type = 'references') => {
            const page = computedProps.page.value;
            const refs = computedProps.refs.value;
            const refsAny = refs as any;
            if (type === 'share') {
                return props.seoFunc ? props.seoFunc() : null;
            } else if (type === 'title') {
                // check first SEO
                const t = computedProps.seo.value?.title || null;
                if (t && t !== '') {
                    return t;
                } else {
                    return page?.base.title || null;
                }
            } else if (type === 'seo') {
                return computedProps.seo.value;
            } else if (type === 'cannonical') {
                return computedProps.cannonical.value;
            } else if (type === 'page') {
                return page;
            } else if (type === 'page.base') {
                if (!id && page && page.base) {
                    return page.base;
                } else if (page && page.base && page.base[id]) {
                    return page.base[id];
                }
                return null;
            } else if (type === 'breadcrumbs') {
                if (page && page.breadcrumbs) {
                    return page.breadcrumbs;
                }
            } else if (refs && refsAny[type]) {
                return refsAny[type].find((p: any) => p.id === id);
            }
            return null;
        },
    };

    return { ...computedProps, ...methods };
};
