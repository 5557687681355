<script setup lang="ts">
    import { PropsType, generatePageMixin, pageProps } from './PageMixin';
    import { useBundleLazyQuery } from '~/graphql/generated';

    //   import {
    //     ACTION_TOGGLE_SHOPPING_CART,
    //     ACTION_LOAD_SHOPINFO,
    //   } from '~/store/app'
    import { useAppStore } from '~/store';
    import { awaitableGQLLazyQuery } from '~/graphql/graphql-helpers';
    import { LoadShopInfoReturnType } from '~/store/app';
    import { OpenCartEventParams } from '~/plugins/event-emitter';

    const props: PropsType = defineProps({
        ...pageProps,
    });

    const { page, pageId, refs, getReference } = generatePageMixin(props);

    const nuxtApp = useNuxtApp();

    console.log('BundlePageRenderer.vue');

    type PageStateType = {
        productsError: string | null;
        shopInfo: LoadShopInfoReturnType; // UNKNOWN_TYPE
        bundleProducts: any; // UNKNOWN_TYPE
    };

    const pageStateDefaultValue: PageStateType = {
        productsError: null,
        shopInfo: [],
        bundleProducts: null,
    };

    const pageState = useState<PageStateType>('bundle-page-renderer-state', () => {
        return pageStateDefaultValue;
    });

    const appStore = useAppStore();

    const preview = computed(() => appStore.state.preview);
    const locale = computed(() => appStore.getters.pageLocale());
    const units = computed(() => appStore.getters.units());

    const bc = computed(() => {
        const bc = page.value?.breadcrumbs;
        if (bc) {
            return bc
                .map((b) => {
                    if (!preview.value && (!b.url || !b.url.locale)) {
                        return undefined;
                    } else {
                        if (!preview.value && (!b.breadcrumb || b.breadcrumb === '')) {
                            return undefined;
                        }
                        const urlPath = b.url && b.url.url !== '/' ? `${b.url.url}` : '';
                        return {
                            text: stripCodes(b.breadcrumb ? b.breadcrumb : b.name),
                            disabled: b.pageId === pageId.value,
                            url: b.url,
                            href: b.url && b.url.locale ? `/${b.url.locale}${urlPath}` : '#',
                            class: preview.value && (!b.url || !b.url.locale || !b.url.url) ? 'not-set' : '',
                        };
                    }
                })
                .filter((p) => !!p);
        }
        return [];
    });

    const gallery = computed(() => {
        const gallery = page.value?.bundle?.gallery || [];
        // console.log('gallery data', gallery)
        return gallery;
    });
    const bundle = computed(() => {
        return page.value?.bundle || null;
    });
    const title = computed(() => {
        return page.value?.base.title || '';
    });

    const hero = computed(() => {
        return page.value?.base.hero || null;
    });
    const toggle = computed(() => {
        return null;
    });
    const products = computed(() => {
        const ps = page.value?.bundle?.products.components;
        if (ps) {
            return ps;
        }
        return [];
    });
    const stripCodes = (text: string) => {
        if (text) {
            text = text.replace(/%{/g, '');
            text = text.replace(/%\[/g, '');
            text = text.replace(/%\(/g, '');
            text = text.replace(/}%/g, '');
            text = text.replace(/\]%/g, '');
            text = text.replace(/\)%/g, '');
        }
        return text;
    };

    const fetch = async () => {
        // console.log('loading products and shopInfo', this.page.bundle.products.components)
        try {
            let ids = products.value.map((m: any) => m?.shopify?.handle || null);
            if (!preview.value) {
                let validIds = true;
                for (const id of ids) {
                    if (!id) {
                        validIds = false;
                    }
                }
                if (!validIds) {
                    pageState.value.productsError = `Bundle Product missing`;

                    return;
                }
            }
            // remove the null handle, we're in preview
            ids = ids.filter((p: any) => !!p);
            const code = bundle.value?.code ? bundle.value?.code.replace(/[\s]/gi, '') : (Math.random() * 1234567).toString();
            // console.log('code', code)

            const loadShopInfoPromise = appStore.actions.loadShopInfo();

            const loadBundlePromise = awaitableGQLLazyQuery(
                useBundleLazyQuery(
                    {
                        id: `${pageId.value}${code}`,
                        locale: locale.value,
                        products: products.value
                            .map((p: any) => {
                                if (p.shopify) {
                                    const filters = [];
                                    for (let i = 1; i < 4; i += 1) {
                                        if (p.shopify[`option${i}`]) {
                                            // console.log('got shopify option', p.shopify[`option${i}`])
                                            const o = p.shopify[`option${i}`];
                                            filters.push({
                                                key: o.name,
                                                value: o.value,
                                            });
                                        }
                                    }
                                    return {
                                        handle: p.shopify.handle,
                                        filters,
                                    };
                                }
                                return null;
                            })
                            .filter((p: any) => !!p),
                    },
                    {
                        fetchPolicy: 'network-only',
                    },
                ),
            );

            await Promise.all([loadShopInfoPromise, loadBundlePromise]);

            pageState.value.shopInfo = await loadShopInfoPromise;

            const res = await loadBundlePromise;

            const resProducts = res.data.bundle?.products || [];
            // console.log('resProducts', resProducts)

            const bundleProducts = bundle.value?.products.components || [];
            const mergedProducts = [];
            for (const pro of resProducts) {
                const f = bundleProducts.find((p: any) => p.shopify && p.shopify.handle === pro.handle);
                // console.log('got pro', pro, f)
                mergedProducts.push({
                    ...pro,
                    title: f.title,
                    description: f.description,
                    orderCode: f.shopify && f.shopify.code ? f.shopify.code : '',
                });
            }
            pageState.value.bundleProducts = mergedProducts;
            // console.log('setting bundle products', this.bundleProducts)
            // console.log('got products', this.bundleProducts, resData[1], bundleProducts)

            
        } catch (err) {
            throw err;
        }
    };

    fetch();

    const openCart = async (item: OpenCartEventParams) => {
        console.log('open cart', item);
    };

    const onVariantChange = (variant: any) => {
        // UNKNOWN_TYPE
        // console.log('on variant change', variant)
    };

    onMounted(() => {
        /*
      if(this.isBike) {
        const sp = this.locale.split('_')
        const id = `${sp[1].toLowerCase()}:${sp[0].toLowerCase()}:collection:${this.page.product.handle}-configurator`
        // fetch only collection
        try {
          this.$apollo.query({
            query: QUERY_COLLECTION,
            variables: {
              id,
              locale: this.locale,
            },
          }).then(data => {
            this.configurator = get(data, 'data.collection.products.items',[])
          }).catch(() => {})
        } catch(err) {
          // catch
        }
      }
      */
        if (process.client) {
            nuxtApp.$eventEmitter.on('open-cart', openCart);
        }
        if (nuxtApp.$gtm) {
            setTimeout(() => {
                nuxtApp.$gtm.ecommerceBundlePage(page.value, pageState.value.bundleProducts);
            }, 20);
        }
    });

    onBeforeUnmount(() => {
        if (process.client) {
            nuxtApp.$eventEmitter.off('open-cart', openCart);
        }
    });

    // import { ACTION_CHECK_PROMO } from '~/store/cart'
    // import { QUERY_FETCH_BUNDLE } from '~/apollo/queries/products'

    // const query = useBundleQuery()

    /*

        */
</script>

<template>
    <div
        fluid
        class="page bundle-page content-grid">
        <div
            tag="section"
            class="bundle-header content-grid">
            <!-- <woom-breadcrumbs v-if="bc && bc.length > 0" :bc="bc" /> -->
            <!-- <div class="header-content content-grid"> -->
            <!-- [leave this row commented out] ld-product :product="product" :selected="variant" :variant-images="variantImages" v-once/ -->
            <!-- <product-image v-if="gallery && bundle && bundleProducts" :images="gallery" :type="[]" />
            <bundle-detail
            v-if="bundle && bundleProducts"
            :title="title"
            :hero="hero"
            :bundle="bundle"
            :shop-info="shopInfo"
            :products="bundleProducts"
            :preview="preview"
            :page-id="pageId"
            :ref-lookup="getReference"
            :on-variant-change="onVariantChange"
            :toggle="toggle"
            class="content-grid"
            /> -->
            <!-- </div> -->
        </div>
        <!-- related-products
        :id="page.product.nativeId"
        :is-bike="isBike"
        :handle="page.product.handle"
      / -->
        <!-- render the generic content -->
        <!-- <content-controller
        :data="page.content"
        :ref-lookup="getReference"
        :page-id="pageId"
        class="page-controller content-grid top-controller"
      /> -->
    </div>
</template>

<!-- <style lang="sass">
.bundle-page
  .page-controller:first-child
    & > *:nth-child(1).rich-text
      padding-top: 24px
  .bundle-header
      grid-column: 1 / 6
      position: relative
      +LineBottom
      &.content-grid
        grid-gap: 0
        grid-row-gap: 0
      .header-content
        grid-column: 1 / 6
        .bundle-slider
          grid-column: 1 / 6
        .bundle-detail
          grid-column: 1 / 6

      +minWidthHeight(1024px,10px)
        &.content-grid
          grid-row-gap: 24px
          &.product-bike
            .header-content
              grid-column: 1 / 5
        .header-content
          -grid-template-columns: minmax(24px, 1fr) Min(794px, calc(100% - 430px)) 382px minmax(24px, 1fr)
          grid-column: 2 / 5
          display: flex
          flex-direction: row
          align-items: flex-start
          .bundle-detail
            display: flex
            flex-direction: column
            grid: 1 / 6
            .price-and-availability
              display: flex
              flex-direction: column
</style> -->
