export default {
    locales: [
        'en_INT',
        'de_DE',
        'de_AT',
        'de_CH',
        'it_CH',
        'fr_CH',
        'it_IT',
        'fr_FR',
        'pl_PL',
        'da_DK',
        'sv_SE',
        'nl_NL',
        'en_US',
        'de_DEU',
        'en_DUS',
        'en_DU3',
    ],
    hreflangs: [
        'en',
        'de',
        'de-AT',
        'de-CH',
        'it-CH',
        'fr-CH',
        'it-IT',
        'fr-FR',
        'pl',
        'da-DK',
        'sv-SE',
        'nl-NL',
        'en-US',
        'de-DE',
        'en-US',
        'en-US',
    ],
    indexed: {
        pl_pl: false,
        de_int: false,
        de_deu: false,
        en_dus: false,
        en_du3: false,
        sv_se: false,
        da_dk: false,
        nl_nl: false,

        en_us: true,
        en_int: true,
        de_de: true,
        de_at: true,
        de_ch: true,
        it_ch: true,
        fr_ch: true,
        it_it: true,
        fr_fr: true,
    },
    defaults: [
        { key: 'en', value: 'en_INT' },
        { key: 'de', value: 'de_DE' },
        { key: 'fr', value: 'fr_FR' },
        { key: 'it', value: 'it_IT' },
        { key: 'pl', value: 'pl_PL' },
        { key: 'sv', value: 'sv_SE' },
        { key: 'da', value: 'da_DK' },
        { key: 'nl', value: 'nl_NL' },
    ],
    domains: [
        { sub: 'www', default: 'en', languages: ['en', 'de', 'it', 'fr'] },
        { sub: 'int', default: 'en', languages: ['en', 'de'] },
        { sub: 'at', default: 'de' },
        { sub: 'de', default: 'de' },
        { sub: 'ch', default: 'de', languages: ['de', 'fr', 'it'] },
        { sub: 'fr', default: 'fr' },
        { sub: 'it', default: 'it' },
        { sub: 'pl', default: 'pl' },
        { sub: 'uk', default: 'en', rewrite: 'gb' },
        { sub: 'us', default: 'en' },
        { sub: 'dk', default: 'da' },
        { sub: 'se', default: 'sv' },
        { sub: 'nl', default: 'nl' },
        { sub: 'dus', default: 'en' },
        { sub: 'du3', default: 'en' },
        { sub: 'deu', default: 'de' },
    ],
};
