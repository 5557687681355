// this utility script reformats urls based on the domain configuration in the woom.js file

import woom from '~~/woom';

export const rewriteUrl = (url: string) => {
    const u = new URL(url);
    // check if we are on the language switcher
    let lang = '';
    let path = '';
    let subdomain = 'www';
    const m = u.pathname.match(/^\/([a-zA-Z]{2})_([a-zA-Z]{2,3})(.*)$/i);
    if (m) {
        // console.log(u, m)
        if (m[2]) {
            const lookupSub = m[2].toLowerCase();
            // lookup this subdomain in our domains array
            const config = woom.domains.find((d) => d.sub === lookupSub);
            // if we have a valid subdomain
            if (config) {
                subdomain = lookupSub;
                // check if the language is the default language
                if (m[1].toLowerCase() !== config.default) {
                    lang = `/${m[1].toLowerCase()}`;
                }
                // set the remaining path
                path = m[3];
            }
        }
    } else {
        // append path
        path = u.pathname;
    }
    let rest = '';
    if (u.search !== '') {
        rest = `${u.search}`;
    }
    if (u.hash !== '') {
        rest = `${rest}#${u.hash}`;
    }
    // rebuild the url
    const newUrl = `${u.protocol}//${subdomain}.${u.host}${lang}${path}${rest}`;
    // console.log('rewrite url', u.toString(), newUrl.toString(), normalizeUrl(newUrl))
    return newUrl;
};

const sourceDomain = 'https://test.com'; // the aws endpoint

export const normalizeUrl = (url: string, base_url: string) => {
    const baseUrl = new URL(base_url); // the url base ie: https://staging.woom.dev or https://woom.com
    const hostRegex = new RegExp(`^(?:([a-zA-Z0-9]{2,5}).)${baseUrl.hostname}`, 'i');

    const u = new URL(url);
    // check the host
    const m = u.hostname.match(hostRegex);
    if (m) {
        // we have a possible subdomain match
        const config = woom.domains.find((d) => d.sub === m[1].toLowerCase());
        if (config) {
            // we have a subdomain match
            let rest = '';
            if (u.search !== '') {
                rest = u.search;
            }
            if (u.hash !== '') {
                rest = `${rest}#${u.hash}`;
            }
            // check if we are on the landing page
            if (config.sub === 'www') {
                // landing page pattern
                return `${sourceDomain}${u.pathname}${rest}`;
            } else {
                let lang = config.default;
                let path = u.pathname;
                const region = config.rewrite ? config.rewrite : config.sub;
                // language/region pattern
                // parse the path and find the language subpath - if not present, assume default language
                const pathMatch = u.pathname.match(/^\/(?:([a-zA-Z]{2})[/]?|([a-zA-Z]{2})[/]{1}(.*))$/);
                if (pathMatch) {
                    lang = pathMatch[2];
                    path = `/${pathMatch[3]}`;
                } else {
                    // use default language
                    // already set above - this is only for documentation purposes
                }
                return `${sourceDomain}/${lang.toLowerCase()}_${region.toUpperCase()}${path}${rest}`;
            }
        } else {
            // no defined subdomain found, continue
            return url;
        }
    } else {
        // no rewrite
        return url;
    }
};

/**
 * affects all local strings starting with the pattern /[lang]_[REGION]/
 * @param {string} url
 */
export const rewriteRelativeUrl = (url: string) => {
    if (typeof url === 'string') {
        const m = url.match(/^\/([a-z]{2})_([a-zA-Z]{2,3})(?:\/(.*))?$/);
        console.log(m);
    }
    return url;
};
