<script setup lang="tsx">
    import { h } from 'vue';
    /**
    renders components and mixed content and components
    */
    // TODO: I think we no longer need it, because of auto imports
    import { Widgets } from './Widgets';

    import { pageProps, generateMixin } from './Controller';

    const props = defineProps({
        ...pageProps,
    });

    const { render, ...methods } = generateMixin(props, Widgets);

    // methods.componentExists;

    // export default {
    //     components: {
    //         ...Widgets
    //     },
    //     mixins: [Controller(Widgets)]
    // };

    render();
</script>
