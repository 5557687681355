import { Prettify } from '@vue/shared';
import { textLinks } from '~~/common/utils/textLinks';
import { RefLookupFunction } from '~~/common/types';

const REG_COMP_OR_ITEM = /<object type="application\/kenticocloud"([^>]*)><\/object>/gi;

type PropsTypeInner = {
    data: Object;
    refLookup: RefLookupFunction;
    pageId: string;
    childClass: string;
};

// this is how defineProps wraps types
export type PropsType = Prettify<Readonly<ExtractPropTypes<PropsTypeInner>>>;

export const pageProps = {
    data: {
        type: Object, // Object as () => any,//UNKNOWN_TYPE
        required: true,
    },
    refLookup: {
        type: Function as unknown as () => RefLookupFunction,
        required: true,
    },
    pageId: {
        type: String,
        required: true,
    },
    childClass: {
        type: String,
        default: '',
    },
};

export const generateMixin = (props: PropsType, Widgets: any) => {
    // UNKNOWN_TYPE
    const methods = {
        // we have some cms widget names, that are mapped to already existing components
        getRealName(name: string) {
            switch (name) {
                case 'ProductIntro': {
                    return 'IntroWidget';
                }
                case 'ProductHighlightsContainer': {
                    return 'HighlightsWidgetContainer';
                }
                case 'ProductHighlight': {
                    return 'HighlightWidget';
                }
                case 'BackgroundHeader': {
                    return 'Hero';
                }
                case 'Header': {
                    return 'WoomHeader';
                }
                case 'AssetWidget': {
                    return 'GalleryWidget';
                }
                default: {
                    // console.log('widget name', name)
                    return name;
                }
            }
        },
        fetchComponent(name: string) {
            const compName = this.getRealName(name);
            return compName;
        },
        componentExists(name: string) {
            const compName = this.getRealName(name);
            return !!Widgets[compName];
        },

        log(msg: string) {
            console.log(msg); // eslint-disable-line no-console
        },
        renderPlaceholder(ref: any, children: any[], h: any, cl = {}) {
            // UNKNOWN_TYPE
            // UNKNOWN_TYPE
            if (ref) {
                switch (ref.type) {
                    case 'collection': {
                        children.push(
                            h(this.fetchComponent('DiscoverBikesWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                },
                            }),
                        );
                        break;
                    }
                    case 'bikefinder': {
                        children.push(
                            h(this.fetchComponent('BikeFinderWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                },
                            }),
                        );
                        break;
                    }
                    case 'gdgheader': {
                        children.push(
                            h(this.fetchComponent('GdgHeaderWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                },
                            }),
                        );
                        break;
                    }
                    case 'awards': {
                        children.push(
                            h(this.fetchComponent('AwardWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                },
                            }),
                        );
                        break;
                    }
                    case 'nowcountdown': {
                        children.push(
                            h(this.fetchComponent('NowCountdownWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                    cta: false,
                                },
                            }),
                        );
                        break;
                    }
                    case 'nowcountdowncta': {
                        children.push(
                            h(this.fetchComponent('NowCountdownWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                    cta: true,
                                },
                            }),
                        );
                        break;
                    }
                    case 'cookiepolicy': {
                        children.push(
                            h(this.fetchComponent('CookieWidget'), {
                                class: cl,
                                props: {
                                    data: ref,
                                    refLookup: props.refLookup,
                                    pageId: props.pageId,
                                    cta: true,
                                },
                            }),
                        );
                        break;
                    }
                }
            }
        },
    };

    const render = () => {
        const children = [];

        const data: any = (props.data as any).value;

        // console.log('render contentcontroller')
        if (props.data && (data.value || data.sequence)) {
            if (data.sequence && !data.value) {
                for (const id of data.sequence) {
                    const ref =
                        id && id.indexOf('inline:') === 0
                            ? data.components
                                ? data.components.find((p: any) => p.id === id) // UNKNOWN_TYPE
                                : null
                            : !!props.refLookup && props.refLookup(id);
                    if (ref && Array.isArray(ref.types)) {
                        const cl: any = {}; // UNKNOWN_TYPE
                        if (!!props.childClass && props.childClass !== '') {
                            cl[props.childClass] = true;
                        }
                        if (ref.types.includes('WidgetPlaceholder')) {
                            methods.renderPlaceholder(ref, children, cl);
                        } else {
                            // check reverse order and pick first
                            for (let i = ref.types.length - 1; i >= 0; i -= 1) {
                                const type = ref.types[i];
                                // console.log('check for type', type)
                                if (methods.componentExists(type)) {
                                    children.push(
                                        h(methods.fetchComponent(type), {
                                            key: ref.id,
                                            class: cl,
                                            props: {
                                                data: ref,
                                                refLookup: props.refLookup,
                                                pageId: props.pageId,
                                            },
                                        }),
                                    );

                                    break;
                                } else {
                                    console.log('component not found', type);
                                }
                            }
                        }
                    }
                }
            } else if (data.value) {
                // console.log('data value', data.value);
                // split the values by object, inject the content if just text
                // console.log('content split', this.data.value.split(REG_COMP_OR_ITEM))
                const splitContent = data.value.split(REG_COMP_OR_ITEM);
                for (let v of splitContent) {
                    if (v.indexOf(' data-type=') === 0) {
                        // parse component
                        const m = v.match(/data-rel="([^"]+)".*data-id="([^"]+)"/i);
                        if (m && m[2]) {
                            const id = m[2];
                            const ref =
                                id && id.indexOf('inline:') === 0
                                    ? data.components.find((p: any) => p.id === id) // UNKNOWN_TYPE
                                    : !!props.refLookup && props.refLookup(id);
                            // children.push(h('pre', {class: {'fullbleed': true}}, [JSON.stringify(ref, null, ' ')]))
                            if (ref && Array.isArray(ref.types)) {
                                // console.log('lookup', ref.types)
                                const cl: any = {}; // UNKNOWN_TYPE
                                if (!!props.childClass && props.childClass !== '') {
                                    cl[props.childClass] = true;
                                }
                                // console.log('widget:', ref.types);
                                if (ref.types.includes('WidgetPlaceholder')) {
                                    methods.renderPlaceholder(ref, children, h, cl);
                                } else {
                                    let compFound = false;
                                    for (let i = ref.types.length - 1; i >= 0; i -= 1) {
                                        const type = ref.types[i];
                                        if (methods.componentExists(type)) {
                                            compFound = true;
                                            // console.log('found widget', type, this.fetchComponent(type))

                                            children.push(
                                                h(methods.fetchComponent(type), {
                                                    class: cl,
                                                    props: {
                                                        data: ref,
                                                        refLookup: props.refLookup,
                                                        pageId: props.pageId,
                                                    },
                                                }),
                                            );
                                            break;
                                        }
                                    }
                                    if (!compFound) {
                                        console.log('component not found', ref.types);
                                    }
                                }
                            } else {
                                methods.log(`warning: no config data found for element with id ${id}`);
                            }
                        } else {
                            methods.log(`warning: couldn't parse object declaration in content: ${v}`);
                        }
                    } else if (v && v.trim() !== '' && v.trim() !== '<p><br></p>') {
                        const cl: any = {
                            // UNKNOWN_TYPE
                            'rich-text': true,
                            'text-padding': true,
                        };
                        if (!!props.childClass && props.childClass !== '') {
                            cl[props.childClass] = true;
                        }
                        v = textLinks(v, props.refLookup);
                        // console.log('textSplit', textSplit)
                        children.push(
                            <div
                                class={cl}
                                innerHTML={v}></div>,
                            // h('div', {
                            //     class: cl,
                            //     domProps: { innerHTML: v }
                            // })
                        );
                    }
                }
            }
        }
        return <div class='content-controller'>{...children}</div>;

        // return h('div', { class: { 'content-controller': true } }, children);
    };

    return { ...methods, render };
};
