<script setup lang="ts">
    // import get from 'lodash.get';

    import { ReactiveHead, UseHeadInput, MergeHead, Meta } from '@unhead/vue';
    import ProductPageRenderer from './ProductPageRenderer.vue';

    // import { ACTION_LANGCOUNTRY, ACTION_WEBSITE } from '../../store/app';
    import ContentPageRenderer from './ContentPageRenderer.vue';
    import BundlePageRenderer from './BundlePageRenderer.vue';
    import woom from '~/woom';
    import { paramsArrayToString, setApolloClient } from '~~/common/helpers';
    import { rewriteUrl } from '~~/common/utils/multidomain';
    import { usePageByIdQuery } from '~~/graphql/generated';
    import type { ImageType, PageByUrlQuery_PageByUrl } from '~~/common/types/nestedGraphqlTypes';
    import { useAppStore, useI18nStore } from '~~/store';
    import { awaitableGQLQuery } from '~/graphql/graphql-helpers';
    import { ComponentType } from '~/common/types';

    definePageMeta({
        layout: 'onboarding',
    });

    type UseHeadType = UseHeadInput<MergeHead>;

    const STAGING = process.env?.STAGING || false;
    /**
    purpose of this controller
    - render seo
    - render cannonicals
    - hand over pageData.page to specific renderer
    */

    const route = useRoute();

    const config = useRuntimeConfig();

    const routeParams = computed(() => route.query.params);

    const routeParamID = computed(() => {
        return Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
    });

    const slugsJoined = computed(() => {
        if (Array.isArray(route.params.slug)) {
            return route.params.slug.join('/');
        } else {
            return route.params.slug;
        }
    });

    const appStore = useAppStore();
    const {} = useI18n()
    const i18nStore = useI18nStore();

    const baseUrl = computed(() => appStore.state.baseUrl);
    const header = computed(() => appStore.state.header);
    const multidomain = computed(() => appStore.state.multidomain);
    const originalHref = computed(() => appStore.state.originalHref);
    const preview = computed(() => appStore.state.preview);

    type PageStateType = {
        pageData: PageQuery_PageByUrl;
        set404: boolean;
        langCountry: any; // TODO
        lang: any; // TODO
        region: any; // TODO
    };

    const pageStateDefaultValue: PageStateType = {
        pageData: null,
        set404: false,
        langCountry: null,
        lang: null,
        region: null,
    };

    const pageState = useState<PageStateType>('page-controller-state', () => {
        return pageStateDefaultValue;
    });

    watch(
        () => pageState.value,
        () => {
            console.log('pageState.value changed', pageState.value);
        },
        {
            deep: true,
        },
    );

    const Renderer = computed<ComponentType | null>(() => {
        if (pageState.value.pageData && pageState.value.pageData.page) {
            const types = pageState.value.pageData.types;
            if (types.includes('BundlePage')) {
                return BundlePageRenderer; // resolveComponent('BundlePageRenderer');
            } else if (types.includes('ContentPage')) {
                return ContentPageRenderer; // resolveComponent('ContentPageRenderer');
            } else if (types.includes('ProductPage')) {
                return ProductPageRenderer; // resolveComponent('ProductPageRenderer');
            }
        }
        return null;
    });

    const getSEO = computed(() => {
        const defaultSeo = appStore.getters.seo();
        const title = pageState.value.pageData?.seo?.title || defaultSeo?.title;
        const description = pageState.value.pageData?.seo?.description || defaultSeo.description;

        // pageState.value.pageData?.page.product?.shopImage

        let imageURL: string | null = pageState.value.pageData?.page.product?.shopImage || null;
        let imageData: ImageType = null;
        if (!imageURL) {
            imageData = pageState.value.pageData?.seo?.image || defaultSeo.image;
        }

        if (!imageData) {
            imageData = pageState.value.pageData?.seo?.image;
        }

        if (!imageData) {
            imageData = defaultSeo.image;
        }

        if (imageData) {
            let imgSrc = '';
            if (imageData.id.indexOf('b:') === 0) {
                if (imageData.data.image && imageData.data.image.indexOf('/') === 0) {
                    imgSrc = `${imageData.data.baseUrl}${imageData.data.image}`;
                } else {
                    imgSrc = imageData.data.image;
                }
            } else if (imageData.id.indexOf('a:') === 0) {
                imgSrc = `${imageData.data.src}?w=800`;
            }
            imageURL = imgSrc;
        }

        return {
            title,
            description,
            image: imageURL,
            url: multidomain.value ? rewriteUrl(`https://woom.com${route.fullPath}`) : `https://woom.com${route.fullPath}`,
        };
    });

    let langCountrySet = false;
    // console.log('page created')
    if (process.client) {
        // console.log(
        //   "page created for route",
        //   this.$route.fullPath,
        //   this.$route.meta
        // );
        // set the new values
        if (route.meta.multidomain) {
            // console.log('multidomain')

            pageState.value.lang = route.params.lang ? route.params.lang : route.meta.defaultLanguage;
            pageState.value.region = route.meta.multidomain;
            // console.log('langCountry is', this.lang, this.region)
            pageState.value.langCountry = `${pageState.value.lang}_${pageState.value.region.toUpperCase()}`;
            langCountrySet = true;
        } else {
            // set the langCountry first
            // console.log('set cookie based on path', this.$route.params)
            if (route.params && route.params.langCountry) {
                const langCountry = paramsArrayToString(route.params.langCountry);

                appStore.mutations.setLangCountry(langCountry);

                pageState.value.langCountry = langCountry;
                pageState.value.lang = langCountry.substring(0, 2).toLowerCase();
                pageState.value.region = langCountry.substring(3).toLowerCase();
                langCountrySet = true;
            }
        }
    }
    // console.log('locale', this.$locale)
    if (!langCountrySet && i18nStore.state.locale) {
        // set the common langCountry values
        // console.log('setting langCountry the old way')

        const country = i18nStore.state.locale;

        pageState.value.langCountry = i18nStore.state.locale;
        // this.langCountry = this.$locale.locale;

        pageState.value.lang = pageState.value.langCountry.substring(0, 2).toLowerCase();
        pageState.value.region = pageState.value.langCountry.substring(3).toLowerCase();
    }

    // //   components: {
    // //     ContentPageRenderer,
    // //     ProductPageRenderer,
    // //     BundlePageRenderer,
    // //   },

    //   // key prop for nuxt router-view
    // //   key(route) {
    // //     return route.fullPath;
    // //   },

    //   },
    //   methods: {
    //     // handleLoaderError(err) {
    //     //   // console.log('got error', err) // eslint-disable-line no-console
    //     // },

    const renderCanonical = () => {
        // console.log('got cannonical filter', this.multidomain)
        const canonical: { rel: string; href: string; hreflang?: string }[] = [];
        // don't render canonicals on staging!
        if (STAGING) {
            return canonical;
        }

        const woomIndexedHasLocale = woom.indexed.hasOwnProperty(i18nStore.state.locale.toLowerCase());

        if (!woomIndexedHasLocale) {
            return canonical;
        }
        // if (!woom.indexed[i18nStore.state.locale.toLowerCase()]) {
        //     return canonical;
        // }
        if (pageState.value.pageData && Array.isArray(pageState.value.pageData.cannonical) && pageState.value.pageData.url) {
            const pageUrlPath = pageState.value.pageData.url.url === '/' ? '' : pageState.value.pageData.url.url;
            canonical.push({
                rel: 'canonical',
                href: multidomain.value
                    ? rewriteUrl(`${baseUrl.value}/${pageState.value.pageData.url.locale}${pageUrlPath}`)
                    : `${baseUrl.value}/${pageState.value.pageData.url.locale}${pageUrlPath}`,
            });
            /*
                const pageLocale = this.pageData.url.locale.split('_')
                // set itself
                canonical.push({
                  rel: 'alternate',
                  href: `${this.baseUrl}/${this.pageData.url.locale}${pageUrlPath}`,
                  hreflang: pageLocale.length >= 1 && pageLocale[1].toUpperCase() === 'INT' ? pageLocale[0] : this.pageData.url.locale.replace('_', '-')
                })
                */
            for (const c of pageState.value.pageData.cannonical) {
                const locale = c.locale;
                const urlPath = c.url === '/' ? '' : c.url;
                if (!woom.locales.includes(locale)) {
                    continue;
                }
                if (!woomIndexedHasLocale) {
                    continue;
                }
                const ls = locale.split('_');
                if (ls.length >= 1 && ls[1].toUpperCase() === 'INT') {
                    canonical.push({
                        rel: 'alternate',
                        hreflang: ls[0],
                        href: multidomain.value ? rewriteUrl(`${baseUrl.value}/${c.locale}${urlPath}`) : `${baseUrl.value}/${c.locale}${urlPath}`,
                    });
                } else {
                    canonical.push({
                        rel: 'alternate',
                        hreflang: locale.replace('_', '-'),
                        href: multidomain.value ? rewriteUrl(`${baseUrl.value}/${c.locale}${urlPath}`) : `${baseUrl.value}/${c.locale}${urlPath}`,
                    });
                    if (locale.toLowerCase() === 'en_us') {
                        canonical.push({
                            rel: 'alternate',
                            hreflang: 'en-CA',
                            href: multidomain.value ? rewriteUrl(`${baseUrl.value}/${c.locale}${urlPath}`) : `${baseUrl.value}/${c.locale}${urlPath}`,
                        });
                    }
                }
            }
            // set the x-default pointing to the language switcher
            canonical.push({
                rel: 'alternate',
                hreflang: 'x-default',
                href: multidomain.value ? rewriteUrl(`${baseUrl.value}/`) : `${baseUrl.value}/`,
            });
        }
        return canonical;
    };

    // https://github.com/nuxt/nuxt/discussions/18554#discussioncomment-4788920
    // The useServerSeoMeta composable is identical to useSeoMeta except that it will have no effect (and will return nothing) if called on the client.

    // checktyping for UseHeadType
    const head = computed<UseHeadType>(() => {
        const img = [];
        const defaultSeo = appStore.getters.seo();
        const title = pageState.value.pageData?.seo?.title || defaultSeo.title;

        // console.log("page header", this.pageData);
        if (process.server) {
            const pageDescription = pageState.value.pageData?.seo?.description || defaultSeo.description;

            const description = pageDescription || defaultSeo.description;

            const canonicals = renderCanonical();
            const woomIndexedHasLocale = woom.indexed.hasOwnProperty(i18nStore.state.locale.toLowerCase());
            // check if we are on staging or if the site is not set as indexed
            const noIndexMeta = [];
            if (!woomIndexedHasLocale) {
                noIndexMeta.push({
                    hid: 'googlebot',
                    property: 'googlebot',
                    content: 'noindex',
                });
                noIndexMeta.push({
                    hid: 'robots',
                    property: 'robots',
                    content: 'noindex',
                });
            }
            const imageURL = pageState.value.pageData?.page.product?.shopImage || null;
            let imageData: ImageType = null;
            if (!imageURL) {
                imageData = pageState.value.pageData?.seo?.image || defaultSeo.image;
            }
            if (!imageData) {
                imageData = defaultSeo.image;
            }
            if (imageData && imageData.id) {
                let imgSrc = '';
                if (imageData.id.indexOf('b:') === 0) {
                    if (imageData.data.image && imageData.data.image.indexOf('/') === 0) {
                        imgSrc = `${imageData.data.baseUrl}${imageData.data.image}`;
                    } else {
                        imgSrc = imageData.data.image;
                    }
                } else if (imageData.id.indexOf('a:') === 0) {
                    imgSrc = `${imageData.data.src}?w=800`;
                }
                if (imgSrc !== '') {
                    img.push({ hid: 'og_image', property: 'og:image', content: imgSrc });
                    img.push({
                        hid: 'twitter_image',
                        name: 'twitter:image',
                        content: imgSrc,
                    });
                }
            } else {
                img.push({ hid: 'og_image', property: 'og:image', content: imageURL });
                img.push({
                    hid: 'twitter_image',
                    name: 'twitter:image',
                    content: imageURL,
                });
            }

            const locale = appStore.getters.locale().replace('_', '-');
            const titleTmp: ReactiveHead['title'] = '';
            const tmpOutput: UseHeadType = {
                title,

                htmlAttrs: {
                    lang: locale,
                },
                meta: [],
                link: [...canonicals],
            };

            const meta: Meta[] = [];

            meta.push({ hid: 'og_locale', property: 'og:locale', content: locale });

            // { hid: 'og_url', property: 'og:url', content: process.env.BASE_URL },

            meta.push({
                hid: 'woom-header',
                property: 'woom:header',
                content: header.value,
            });
            if (multidomain.value) {
                meta.push({
                    hid: 'woom-multidomain',
                    property: 'woom:multidomain',
                    content: multidomain.value,
                });
            }
            if (originalHref.value) {
                meta.push({
                    hid: 'woom-href',
                    property: 'woom:href',
                    content: originalHref.value,
                });
            }
            meta.push({
                hid: 'woom-base-url',
                property: 'woom:base',
                content: baseUrl.value,
            });

            if (description) {
                meta.push({ hid: 'description', name: 'description', content: description });
                meta.push({
                    hid: 'og_description',
                    property: 'og:description',
                    content: description,
                });
                meta.push({
                    hid: 'twitter_description',
                    name: 'twitter:description',
                    content: description,
                });
            }

            if (title) {
                meta.push({ hid: 'og_title', property: 'og:title', content: title });
                meta.push({ hid: 'twitter_title', name: 'twitter:title', content: title });
            }

            for (const imgItem of img) {
                if (imgItem.content) {
                    meta.push(imgItem as any);
                }
            }

            meta.push(...noIndexMeta);

            tmpOutput.meta = meta;

            return tmpOutput;
        } else if (process.client) {
            const tmpOutput: UseHeadType = {
                title,
                htmlAttrs: {
                    lang: appStore.getters.locale().replace('_', '-'),
                },
            };

            return tmpOutput;
        } else {
            return {};
        }
    });

    useHead(head.value);

    const fetch = async () => {
        setApolloClient();
        const preview = appStore.state.preview;
        // const preview = this.$store.getters['app/preview'];
        // console.log("page route in $fetch", this.$route);
        if (pageState.value.langCountry) {
            const locale = pageState.value.langCountry;
            const url = slugsJoined.value ? `/${slugsJoined.value}` : '/';

            try {
                const data = await appStore.actions.loadPageByUrl({
                    locale,
                    url,
                });
                console.log('got page data', data);
                pageState.value.pageData = data;
                if (!data) {
                    if (process.server) {
                        pageState.value.set404 = true;

                        // we need to set this manually
                        // and display the error inline
                        // TODO-FROM-OLD-SITE
                        // this.$res.statusCode = 404;
                    } else {
                        console.log('fetch error');
                        // TODO-FROM-OLD-SITE
                        // this.$nuxt.error({
                        //     statusCode: 404,
                        //     message: 'Page not found'
                        // });
                    }
                }
                return data;
            } catch (err) {
                //, arg2: any
                console.log('error', err); //, arg2
                throw err;

                // TODO-FROM-OLD-SITE
                // this.$nuxt.error({
                //     statusCode: 500,
                //     message: err.message
                // });
            }
        } else if (routeParamID.value) {
            const pageByIDQuery = usePageByIdQuery({
                id: routeParamID.value,
                preview,
            });

            try {
                const data = await awaitableGQLQuery(pageByIDQuery);
                const pd = data.data.pageById;
                if (pd?.locale) {
                    // set some environment settings based on the returned locale
                    appStore.actions.setLangCountry(pd.locale);

                    appStore.actions.checkWebsite();
                    return pd;
                }
            } catch (err) {
                console.log('error', err); // eslint-disable-line no-console
                // redirect or 404
                throw err;
            }
        }
    };

    fetch();
</script>

<template>
    <main class="page-container">
        <client-only>
            <template v-if="pageState.pageData && pageState.pageData.locale">
                <notifications :page-locale="pageState.pageData.locale" />
            </template>
        </client-only>
        <div
            v-show="!Renderer && !pageState.set404"
            class="page-loader">
            loader-bike-1
            <Homepage></Homepage>
            <!-- <loader-bike-1 class="active" /> -->
        </div>
        <section
            v-if="pageState.set404"
            class="content-grid">
            woom-error
            <!-- <woom-error
                :status-code="404"
                trans-key="error.404"
                class="cb" /> -->
        </section>
        <Renderer
            v-if="Renderer"
            :page-data="pageState.pageData"
            :seo-func="getSEO" />
    </main>
</template>

<style>
    .page-container {
        min-height: calc(100vh - var(--nav-bar-height));
        max-width: 100vw;
        position: relative;
        z-index: 1;
    }

    .page-container .page-loader {
        display: flex;
        /* flex-direction: row; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }
    @media only screen and (max-width: 960px) {
        .hide-panel {
            display: none;
        }
    }
</style>
