<script setup lang="ts">
    import Vue from 'vue';
    import { ApolloError, ApolloQueryResult } from '@apollo/client';
    import { PropsType, generatePageMixin, pageProps } from './PageMixin';
    import { useAppStore, useCartStore } from '~~/store';
    import {
        ProductQuery,
        useProductLazyQuery,
        useCollectionLazyQuery,
        CollectionQuery,
        useBundlesLazyQuery,
        BundlesQuery,
    } from '~~/graphql/generated';
    import {
        BundlesQuery_Bundle,
        BundlesQuery_Bundles,
        CollectionQuery_Collection_Products_Item,
        PageQuery_PageByUrl_Page_Product_Promo,
    } from '~~/common/types/nestedGraphqlTypes';
    import { awaitableGQLLazyQuery } from '~/graphql/graphql-helpers';
    // import ProductSpecification from "~/components/Product/ProductSpecification";
    // import ProductConfigurator from "~/components/Product/ProductConfigurator";
    // import BikeGeometry from "~/components/Product/BikeGeometry";
    // import CartPromotion from "~/components/Cart/CartPromotion";

    // placeholder - remove later when components are properly implemented
    import Placeholder from '~/components/atm/Placeholder.vue';
    import { GlobalDrawerEventParams, OpenCartEventParams } from '~/plugins/event-emitter';

    // import { ACTION_TOGGLE_SHOPPING_CART } from "~/store/app";
    // import { ACTION_CHECK_PROMO } from "~/store/cart";

    // TODO: FROM-OLD-SITE
    const CartPromotion = Placeholder;
    const ProductConfigurator = Placeholder;
    const BikeGeometry = Placeholder;
    const ProductSpecification = Placeholder;

    const nuxtApp = useNuxtApp();

    const appStore = useAppStore();
    const cartStore = useCartStore();

    const props: PropsType = defineProps({
        ...pageProps,
    });

    const { page, pageId, refs, getReference } = generatePageMixin(props);

    type PageStateType = {
        configurator: CollectionQuery_Collection_Products_Item[] | undefined;
        promotion: PageQuery_PageByUrl_Page_Product_Promo | null; // UNKNOWN_TYPE
        bundleData: BundlesQuery_Bundles;
    };

    const pageStateDefaultValue: PageStateType = {
        configurator: [],
        promotion: null,
        bundleData: null,
    };

    const pageState = useState<PageStateType>('product-page-renderer-state', () => {
        return pageStateDefaultValue;
    });

    const preview = computed(() => appStore.state.preview);

    const locale = computed(() => appStore.getters.pageLocale());

    const units = computed(() => appStore.getters.units());

    const isBike = computed(() => {
        if (page.value && page.value?.product && page.value?.product.types) {
            return page.value?.product.types.indexOf('Bike') >= 0;
        }
        return false;
    });

    const hasSpecs = computed(() => {
        if (page.value && page.value?.content && page.value?.content.specification) {
            // check if it contains anything
            return page.value?.content.specification.sequence && page.value?.content.specification.sequence.length > 0;
        }
        return false;
    });

    const hasTopControllerContent = computed(() => {
        if (page.value && page.value?.content && page.value?.content.value) {
            const v = page.value?.content.value.value;
            return v && v !== '' && v !== '<p><br></p>';
        }
        return false;
    });

    const hasEndControllerContent = computed(() => {
        if (page.value && page.value?.content && page.value?.content.end) {
            return page.value?.content.end.sequence && page.value?.content.end.sequence.length > 0;
        }
        return false;
    });

    const bundles = computed(() => {
        const b = page.value?.content.bundles || '';
        if (b && b !== '') {
            const ret = [];
            const s = b.split(',');
            for (const bu of s) {
                ret.push(bu.trim());
            }
            return ret;
        }
        return [];
    });

    const upsellData = computed(() => {
        const ret = [];
        if (pageState.value.bundleData && pageState.value.bundleData.length > 0) {
            for (const b of pageState.value.bundleData) {
                ret.push({
                    ...b,
                    display: 'bundle',
                });
            }
        }
        if (pageState.value.configurator && pageState.value.configurator.length > 0) {
            for (const c of pageState.value.configurator) {
                ret.push({
                    ...c,
                    display: 'product',
                });
            }
        }
        return ret;
    });

    const hideRelated = computed(() => {
        if (page.value && page.value.product) {
            return page.value.product.tags?.includes('PDP-RELATED-HIDE');
        }
        return false;
    });

    onMounted(async () => {
        // if this is a bike, then let's load the configurator products (if they exist)
        if (page.value && page.value.product && page.value.product.promo) {
            pageState.value.promotion = {
                ...page.value.product.promo,
            };

            const productQuery = useProductLazyQuery({
                id: pageState.value.promotion.promo,
            });

            try {
                const data = await awaitableGQLLazyQuery(productQuery);
                pageState.value.promotion.product = data.data.product || null;
                if (pageState.value.promotion.content && typeof pageState.value.promotion.content === 'string') {
                    pageState.value.promotion.content = JSON.parse(pageState.value.promotion.content);
                }
            } catch (err) {}
        }
        if (isBike.value) {
            const sp = locale.value.split('_');
            const id = `${sp[1].toLowerCase()}:${sp[0].toLowerCase()}:collection:${page.value?.product?.handle}-configurator`;
            // fetch only collection
            try {
                const collectionQuery = useCollectionLazyQuery({
                    id,
                    // locale: this.locale
                    // this "locale" value doesn't seem to be actually used by the query?
                    // it takes a preview paramter, that isn't actually used either
                });

                try {
                    const data = await awaitableGQLLazyQuery(collectionQuery);
                    pageState.value.configurator = data.data.collection?.products.items;
                } catch (err) {}
            } catch (err) {
                // catch
                // console.log('error on catch', err)
            }
        }
        if (bundles.value.length > 0) {
            const bundlesQuery = useBundlesLazyQuery({
                ids: bundles.value,
                locale: locale.value,
                preview: preview.value,
            });

            try {
                const data = await awaitableGQLLazyQuery(bundlesQuery);

                const bs = data.data.bundles || [];
                if (Array.isArray(bs) && bs.length > 0) {
                    const r: BundlesQuery_Bundle[] = [];
                    for (const b of bs) {
                        if (b.availableForSale) {
                            r.push(b);
                        }
                    }
                    pageState.value.bundleData = r;
                    // console.log('got bundle data', this.bundleData)
                }
            } catch (err) {}
        }

        onMounted(() => {
            if (process.client) {
                nuxtApp.$eventEmitter.on('open-cart', openCart);
                nuxtApp.$eventEmitter.on('open-geometry', loadGeometry);
                nuxtApp.$eventEmitter.on('open-specs', loadSpecs);
                // TODO-FROM-OLD-SITE
                if (nuxtApp.$gtm) {
                    setTimeout(() => {
                        nuxtApp.$gtm.ecommerceProductPage(page.value);
                    }, 20);
                }
            }
        });
    });

    onBeforeUnmount(() => {
        if (process.client) {
            nuxtApp.$eventEmitter.off('open-cart', openCart);
            nuxtApp.$eventEmitter.off('open-geometry', loadGeometry);
            nuxtApp.$eventEmitter.off('open-specs', loadSpecs);
        }
    });

    const openCart = async (item: OpenCartEventParams) => {
        // check if this is the main product or any related product

        const sameProduct = item && item.product === page.value?.product?.id;
        let usePromo = false;
        if (sameProduct && pageState.value.promotion && pageState.value.promotion.product) {
            usePromo = cartStore.actions.canAddPromo({
                variant: item,
                promo: pageState.value.promotion.promo,
            });
        }

        if (sameProduct && usePromo) {
            nuxtApp.$eventEmitter.emit('global-drawer', {
                component: CartPromotion,
                // pageState.value.promotion?.content is a string - so title will never exist?
                // label: pageState.value.promotion?.content?.title || 'Promo',
                label: (pageState.value.promotion?.content as any)?.title || 'Promo',
                content: {
                    variant: item,
                    data: pageState.value.promotion,
                    product: page.value?.product,
                    pageId: pageId.value,
                    refLookup: getReference,
                },
            });
        } else if (sameProduct && upsellData.value.length > 0) {
            nuxtApp.$eventEmitter.emit('global-drawer', {
                component: ProductConfigurator,
                label: '',
                content: {
                    variant: item,
                    data: upsellData.value,
                    category: page.value?.product?.category,
                },
                product: page.value?.product,
                pageId: pageId.value,
                refLookup: getReference,
            });
        } else {
            appStore.mutations.toggleShoppingCart(true);
        }
    };

    const loadSpecs = () => {
        nuxtApp.$eventEmitter.emit('global-drawer', {
            component: ProductSpecification,
            label: i18n.t('product.specs.label'),
            content: page.value?.content.specification,
            product: null,
            pageId: pageId.value,
            refLookup: getReference,
        });
    };

    const i18n = useI18n();

    const loadGeometry = () => {
        if (page.value?.content.geometry) {
            getReference(page.value.content.geometry);
        }
        nuxtApp.$eventEmitter.emit('global-drawer', {
            component: BikeGeometry,
            label: i18n.t('product.geometry.label'),
            content: {
                geometry: page.value?.content.geometry,
                units: units.value,
            },
            product: page.value?.product,
            pageId: pageId.value,
            refLookup: getReference,
        });
    };
</script>

<template>
    <div
        fluid
        class="page product-page content-grid">
        <product-header
            v-if="page?.product"
            :content="page"
            :promo="pageState.promotion"
            :page-id="pageId"
            :ref-lookup="getReference"
            :has-specs="hasSpecs"
            :has-geometry="page && page.content && !!page.content.geometry" />
        <related-products
            v-if="!hideRelated"
            :id="page?.product?.nativeId"
            :is-bike="isBike"
            :handle="page?.product?.handle" />
        <!-- render the generic content -->
        <cms-content-controller
            v-if="hasTopControllerContent"
            :data="page?.content.value"
            :ref-lookup="getReference"
            :page-id="pageId"
            class="page-controller content-grid top-controller" />

        <!-- related products come here -->
        <cms-content-controller
            v-if="hasEndControllerContent"
            :data="page?.content.end"
            :ref-lookup="getReference"
            :page-id="pageId"
            class="page-controller content-grid" />
    </div>
</template>
